import React, {useMemo} from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'

interface IProps {
    category: string
    className?: string
}

const WalletTransactionCategoryTag: React.FC<IProps> = ({category, className}) => {
    const {t} = useTranslation()

    const backgroundColor = useMemo(() => {
        switch (category) {
            case 'leader-pay':
            case 'recharge-bank':
            case 'recharge-paypal':
            case 'recharge-crypto':
                return 'bg-primary_tint01 dark:bg-dark_primary_tint01'
            case 'spend-copybot':
            case 'withdraw':
                return 'bg-red_tint01 dark:bg-dark_red_tint01'
            default:
                return 'bg-gray07 dark:bg-dark_gray07'
        }
    }, [category])

    const textColor = useMemo(() => {
        switch (category) {
            case 'leader-pay':
            case 'recharge-bank':
            case 'recharge-paypal':
            case 'recharge-crypto':
            case 'reward':
            case 'referral-reward':
                return 'text-primary_shade dark:text-dark_primary_shade'
            case 'spend-copybot':
            case 'withdraw':
                return 'text-red_shade dark:text-dark_red_shade'
            default:
                return 'text-gray01 dark:text-dark_gray01'
        }
    }, [category])

    const text = useMemo(() => {
        const key = `wallet.transactionHistory.category.${category}`
        const translation = t(key)
        if (translation === key) {
            return category
        }
        return translation
    }, [category, t])

    return <Text className={`${textColor} text-ti1 sm:text-body2`}>{text}</Text>
}

export default React.memo(WalletTransactionCategoryTag)
