import React, {useMemo, useRef, useState} from 'react'
import {TableHeaderContainer} from '@component/tables/TableHeaderContainer'
import Text from '@component/Text/Text'
import EmptyComponent from '@feature/common/components/EmptyComponent/EmptyComponent'
import {useTranslation} from 'next-i18next'
import useFormatNumber from '@hook/useFormatNumber'
import useFormatDate from '@hook/useFormatDate'
import {IWithdrawHistory} from '@api/user/account/wallet/withdraw/getWithdrawHistory'
import useQueryWithdrawHistory from '@hook/query/account/wallet/useQueryWithdrawHistory'
import IconCopy from '@svg/common/ic_copy.svg'
import useCopy from '@hook/useCopy'
import useSnackbar from '@hook/useSnackbar'
import WizButton from '@component/buttons/WizButton'
import {IWalletTable} from '@feature/account/pages/wallet/WalletAllHistoryTableComponent/WalletAllHistoryTableComponent'
import WithdrawCancelModal, {
    IWithdrawModal,
} from '@feature/account/pages/wallet/WithdrawCancelModal/WithdrawCancelModal'
import MoreButton from '@feature/common/components/MoreButton/MoreButton'
import IconNext from '@svg/common/ic_btn_next.svg'
import {cutMidString} from '@util/strings'
import {createLog} from '@util/logs'

const WalletWithdrawHistoryTableComponent: React.FC<IWalletTable> = ({className, size}) => {
    const {t} = useTranslation()
    const {displayNumber} = useFormatNumber()
    const {displayDateFromText} = useFormatDate()
    const {copyToClipboard} = useCopy()
    const {showSnackbar} = useSnackbar()
    const [withdrawState, setWithdrawState] = useState<IWithdrawModal>()
    const withdrawModalRef = useRef<any>()

    const onClickCopyWalletId = id => {
        copyToClipboard(id)
        showSnackbar(t('wallet.history.withdraw.table.row.id.copy'))
    }

    const {data: withdrawHistory, fetchNextPage, isReachingEnd} = useQueryWithdrawHistory()

    const withdrawHistoryList = useMemo(() => {
        let list: IWithdrawHistory[] = []
        withdrawHistory?.pages?.map(page => page?.map(item => list.push(item)))
        return size ? list.slice(0, size) : list
    }, [withdrawHistory, size])

    const openWithdrawModal = (id, coin, amount) => {
        setWithdrawState({
            id: id,
            coin: coin,
            amount: amount,
        })
        withdrawModalRef?.current.show()
    }

    const onClickMoreFetchTx = () => {
        createLog('event', 'account_wallet_history_click_more_fetch_history_withdraw')
        void fetchNextPage()
    }

    return (
        <>
            <TableHeaderContainer className={'grid grid-cols-8 md:hidden'}>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                    {t('wallet.history.withdraw.table.column.categories')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                    {t('wallet.history.withdraw.table.column.date')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                    {t('wallet.history.withdraw.table.column.amount')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                    {t('wallet.history.withdraw.table.column.asset')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                    {t('wallet.history.withdraw.table.column.fee')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                    {t('wallet.history.withdraw.table.column.status')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                    {t('wallet.history.withdraw.table.column.id')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02 text-right'}>
                    {t('wallet.history.withdraw.table.column.action')}
                </Text>
            </TableHeaderContainer>

            {withdrawHistoryList?.length > 0 ? (
                <>
                    {withdrawHistoryList?.map(item => {
                        return (
                            <>
                                <div
                                    key={item.id}
                                    className={`grid grid-cols-8 py-[15px] px-[20px] md:px-0 items-center md:grid-cols-2 md:gap-y-[5px] ${className}`}>
                                    <Text className={'text-ti1 text-red_shade dark:text-dark_red_shade sm:text-body2'}>
                                        {t('wallet.transactionHistory.category.withdraw')}
                                    </Text>
                                    <Text className={'md:text-right text-gray01 dark:text-dark_gray01 sm:text-body2'}>
                                        {displayDateFromText(item.created_at, t('dateFormat.dateAndTime'))}
                                    </Text>
                                    <div className={'md:col-span-2 flex justify-between items-center'}>
                                        <Text
                                            className={
                                                'hidden md:block text-gray01 dark:text-dark_gray01 sm:text-body2'
                                            }>
                                            {t('wallet.history.withdraw.table.row.amount')}
                                        </Text>
                                        <Text className={'text-ti1 text-gray01 dark:text-dark_gray01 sm:text-ti2'}>
                                            {displayNumber(Math.abs(item.amount), 2)}
                                        </Text>
                                    </div>
                                    <div className={'md:col-span-2 flex justify-between items-center'}>
                                        <Text
                                            className={
                                                'hidden md:block text-gray01 dark:text-dark_gray01 sm:text-body2'
                                            }>
                                            {t('wallet.history.withdraw.table.row.asset')}
                                        </Text>
                                        <Text className={'text-ti1 text-gray01 dark:text-dark_gray01 sm:text-ti2'}>
                                            {item.coin}
                                        </Text>
                                    </div>
                                    <div className={'md:col-span-2 flex justify-between items-center'}>
                                        <Text
                                            className={
                                                'hidden md:block text-gray01 dark:text-dark_gray01 sm:text-body2'
                                            }>
                                            {t('wallet.history.withdraw.table.row.fee')}
                                        </Text>
                                        <Text className="text-gray01 dark:text-dark_gray01 sm:text-body2">
                                            {displayNumber(item.fee)}
                                        </Text>
                                    </div>
                                    <div className={'md:col-span-2 flex justify-between items-center'}>
                                        <Text
                                            className={
                                                'hidden md:block text-gray01 dark:text-dark_gray01 sm:text-body2'
                                            }>
                                            {t('wallet.history.withdraw.table.row.status')}
                                        </Text>
                                        <Text className="text-gray01 dark:text-dark_gray01 sm:text-body2">
                                            {t(`wallet.history.withdraw.table.status.${item.status}`)}
                                        </Text>
                                    </div>
                                    <div className={'md:col-span-2 flex md:justify-between items-center'}>
                                        <Text
                                            className={
                                                'hidden md:block text-gray01 dark:text-dark_gray01 sm:text-body2'
                                            }>
                                            {t('wallet.history.withdraw.table.row.id')}
                                        </Text>
                                        <div className={'flex items-center gap-[10px]'}>
                                            <Text
                                                className={
                                                    'text-body1 text-gray01 dark:text-dark_gray01 sm:text-body2 '
                                                }>
                                                {cutMidString(item.chain_tx_id || '-', 4)}
                                            </Text>
                                            {item.chain_tx_id && (
                                                <IconCopy
                                                    onClick={() => {
                                                        onClickCopyWalletId(item.chain_tx_id)
                                                    }}
                                                    width={14}
                                                    className={'fill-gray02 dark:fill-dark_gray02 cursor-pointer'}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    {(item?.status === 'created' || item?.status === 'pending') && (
                                        <div className={'justify-self-end md:justify-self-auto md:col-span-2'}>
                                            <WizButton
                                                size={'small'}
                                                buttonType={'border'}
                                                text={t('wallet.history.withdraw.button.cancel')}
                                                className={'md:w-full'}
                                                onClick={() => openWithdrawModal(item?.id, item?.coin, item?.amount)}
                                            />
                                        </div>
                                    )}
                                </div>
                            </>
                        )
                    })}

                    {!isReachingEnd && !size && (
                        <MoreButton
                            onClick={onClickMoreFetchTx}
                            className={'mx-auto mt-[10px]'}
                            renderRight={<IconNext className={'w-[16px] fill-gray01 dark:fill-dark_gray01'} />}
                        />
                    )}
                </>
            ) : (
                <EmptyComponent
                    text={t('wallet.transactionHistory.empty.text')}
                    className={'h-36 bg-transparent dark:bg-transparent'}
                />
            )}
            <WithdrawCancelModal ref={withdrawModalRef} data={withdrawState} />
        </>
    )
}

export default WalletWithdrawHistoryTableComponent
