import React, {useImperativeHandle, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import {GuideScreenshot} from '@type/exchange'
import {addImageWebpQuery} from '@util/images'
import WizContentModal from '@component/modal/WizContentModal'
import Link from 'next/link'
import {createLog} from '@util/logs'

interface IProps {
    permission?: GuideScreenshot[]
}

const ApiPermissionModal = ({permission}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
    }))

    return (
        <>
            <WizContentModal title={t('modal.api.permission.title')} size={'800'} ref={modalRef}>
                <div className={'mt-[30px] space-y-[5px] max-h-[75vh] overflow-y-auto pr-[10px] not_bg_scrollbar'}>
                    {permission?.map(item => (
                        <Link
                            key={item.image}
                            href={addImageWebpQuery(item.image, 1920)}
                            onClick={() =>
                                createLog('event', 'portfolio_exchange_permissions_notice_click_guide_screenshot')
                            }
                            target={'_blank'}>
                            <img
                                src={addImageWebpQuery(item.image, 1920)}
                                className={'cursor-pointer'}
                                alt={'exchange_permission_image'}
                            />
                        </Link>
                    ))}
                </div>
            </WizContentModal>
        </>
    )
}

export default React.forwardRef(ApiPermissionModal)
