import React, {useImperativeHandle, useRef} from 'react'
import WizContentModal from '@component/modal/WizContentModal'
import {useTranslation} from 'next-i18next'
import useSnackbar from '@hook/useSnackbar'
import {useQueryClient} from '@tanstack/react-query'
import {useLoginStore} from '@store/LoginStore'
import Text from '@component/Text/Text'
import WizButton from '@component/buttons/WizButton'
import {apiWithdrawCryptoCancel} from '@api/user/account/wallet/withdraw/postWithdrawCancel'
import {QueryCacheKeys} from '@hook/query/queryKeys'

export interface IWithdrawModal {
    id: string
    coin: string
    amount: number
}

interface IProps {
    className?: string
    data?: IWithdrawModal
}

const WithdrawCancelModal = ({className, data}: IProps, ref: any) => {
    const {t} = useTranslation()
    const modalRef = useRef<any>()
    const {showSnackbar, showErrorSnackbar} = useSnackbar()
    const queryClient = useQueryClient()
    const myId = useLoginStore(state => state.id)

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
    }))

    const onClickWithdrawCancel = async () => {
        const {data: withdrawCancel, error} = await apiWithdrawCryptoCancel(data?.id)
        if (withdrawCancel) {
            showSnackbar(t('modal.withdraw.cancel.success.snackbar.title'))
        } else {
            showErrorSnackbar(error)
        }
        modalRef.current.close()
        queryClient.invalidateQueries({queryKey: QueryCacheKeys.wallet.getWithdrawHistory(myId)})
    }

    return (
        <WizContentModal size={'400'} ref={modalRef} title={t('modal.withdraw.cancel.title')}>
            <div className={`${className} mt-[30px]`}>
                <Text className="text-gray01 dark:text-dark_gray01">
                    {t('modal.withdraw.cancel.desc', {
                        amount: data?.amount,
                        coin: data?.coin,
                    })}
                </Text>
            </div>
            <div className={'mt-[30px] flex justify-center space-x-[5px] items-center'}>
                <WizButton
                    onClick={() => modalRef?.current.close()}
                    text={t('modal.withdraw.cancel.no')}
                    className={'flex-1'}
                    buttonType={'border'}
                />
                <WizButton
                    onClick={() => onClickWithdrawCancel()}
                    text={t('modal.withdraw.cancel.yes')}
                    className={'flex-1'}
                    buttonType={'primary'}
                />
            </div>
        </WizContentModal>
    )
}

export default React.forwardRef(WithdrawCancelModal)
