import React from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import SkeletonBlock from '@component/skeleton/SkeletonBlock'

interface IProps {
    className?: string
    isPaidByOther?: boolean
    name?: string
    isLoading?: boolean
}

const InvoicePaidByTag: React.FC<IProps> = ({className, name, isPaidByOther = false, isLoading}) => {
    const {t} = useTranslation()

    const getText = () => {
        if (isPaidByOther) {
            return t('invoice.paidByTag.type.paidByOther', {name: name})
        } else {
            return t('invoice.paidByTag.type.myInvoice')
        }
    }

    const getStyle = () => {
        if (isPaidByOther) {
            return 'text-body_comm sm:text-ti3 text-white dark:text-bg_dark_background rounded-[11px] px-[7px] py-[3.5px] sm:py-[2px] bg-gray01 dark:bg-dark_gray01'
        } else {
            return 'text-ti4 sm:text-ti3 text-white dark:text-bg_dark_background rounded-[11px] px-[7px] py-[3.5px] sm:py-[1px] bg-primary dark:bg-dark_primary'
        }
    }
    if (isLoading) {
        return <SkeletonBlock className={'w-[100px] h-[22px]'} sizeClassName={'h-full rounded-full'} />
    }
    return (
        <div className={`${getStyle()} ${className}`}>
            <Text className={'flex justify-center items-center whitespace-nowrap'} enablePreWhiteSpace={false}>
                {getText()}
            </Text>
        </div>
    )
}

export default InvoicePaidByTag
