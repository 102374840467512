import React, {useMemo} from 'react'
import {TableHeaderContainer} from '@component/tables/TableHeaderContainer'
import Text from '@component/Text/Text'
import EmptyComponent from '@feature/common/components/EmptyComponent/EmptyComponent'
import {useTranslation} from 'next-i18next'
import useFormatNumber from '@hook/useFormatNumber'
import useFormatDate from '@hook/useFormatDate'
import useCopy from '@hook/useCopy'
import useSnackbar from '@hook/useSnackbar'
import {IWalletTable} from '@feature/account/pages/wallet/WalletAllHistoryTableComponent/WalletAllHistoryTableComponent'
import WalletTransactionCategoryTag from '@feature/account/pages/wallet/dashboard/WalletTransactionHistoryContainer/CategoryTag/WalletTransactionCategoryTag'
import IconCopy from '@svg/common/ic_copy.svg'
import useQueryPaymentTransaction from '@hook/query/account/wallet/useQueryPaymentTransaction'
import {IWalletTransaction} from '@api/user/account/wallet/getWalletTransaction'
import MoreButton from '@feature/common/components/MoreButton/MoreButton'
import IconNext from '@svg/common/ic_btn_next.svg'
import {cutMidString} from '@util/strings'
import {createLog} from '@util/logs'

const WalletDepositHistoryTableComponent: React.FC<IWalletTable> = ({className, size}) => {
    const {t} = useTranslation()
    const {displayNumber} = useFormatNumber()
    const {displayDateFromText} = useFormatDate()
    const {copyToClipboard} = useCopy()
    const {showSnackbar} = useSnackbar()
    const {data: paymentDepositData, isReachingEnd, fetchNextPage} = useQueryPaymentTransaction('deposit')

    const onClickCopyWalletId = id => {
        copyToClipboard(id)
        showSnackbar(t('wallet.history.deposit.table.row.id.copy'))
    }

    const paymentDepositList = useMemo(() => {
        let list: IWalletTransaction[] = []
        paymentDepositData?.pages?.map(page => page?.map(item => list.push(item)))
        return size ? list.slice(0, size) : list
    }, [paymentDepositData, size])

    const onClickMoreFetchTx = () => {
        createLog('event', 'account_wallet_history_click_more_fetch_history_deposit')
        void fetchNextPage()
    }

    return (
        <>
            <TableHeaderContainer className={'grid grid-cols-5 md:hidden'}>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                    {t('wallet.history.deposit.table.column.categories')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                    {t('wallet.history.deposit.table.column.date')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                    {t('wallet.history.deposit.table.column.amount')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                    {t('wallet.history.deposit.table.column.asset')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                    {t('wallet.history.deposit.table.column.id')}
                </Text>
            </TableHeaderContainer>

            {paymentDepositList?.length > 0 ? (
                <>
                    {paymentDepositList?.map(item => (
                        <div
                            key={item.id}
                            className={`grid grid-cols-5 py-[15px] px-[20px] md:px-0 items-center md:grid-cols-2 md:gap-y-[5px] ${className}`}>
                            <WalletTransactionCategoryTag category={item.action} />
                            <Text className={'md:text-right text-gray01 dark:text-dark_gray01 sm:text-body2'}>
                                {displayDateFromText(item.created_at, t('dateFormat.dateAndTime'))}
                            </Text>
                            <div className={'md:col-span-2 flex justify-between items-center'}>
                                <Text className={'hidden md:block text-gray01 dark:text-dark_gray01 sm:text-body2'}>
                                    {t('wallet.history.all.table.row.amount')}
                                </Text>
                                <Text className={'text-ti1 text-gray01 dark:text-dark_gray01 sm:text-ti2'}>
                                    {displayNumber(Math.abs(item.amount), 2)}
                                </Text>
                            </div>
                            <div className={'md:col-span-2 flex justify-between items-center'}>
                                <Text className={'hidden md:block text-gray01 dark:text-dark_gray01 sm:text-body2'}>
                                    {t('wallet.history.all.table.row.asset')}
                                </Text>
                                <Text className="text-gray01 dark:text-dark_gray01 sm:text-body2">{item.coin}</Text>
                            </div>
                            <div className={'md:col-span-2 flex md:justify-between items-center'}>
                                <Text className={'hidden md:block text-gray01 dark:text-dark_gray01 sm:text-body2'}>
                                    {t('wallet.history.withdraw.table.row.id')}
                                </Text>
                                <div className={'flex items-center gap-[10px]'}>
                                    <Text className="text-gray01 dark:text-dark_gray01 sm:text-body2">
                                        {cutMidString(JSON.parse(item?.extra)?.tx_id || '-', 4)}
                                    </Text>
                                    {JSON.parse(item?.extra)?.tx_id && (
                                        <IconCopy
                                            onClick={() => {
                                                onClickCopyWalletId(JSON.parse(item?.extra)?.tx_id)
                                            }}
                                            width={14}
                                            className={'fill-gray02 dark:fill-dark_gray02 cursor-pointer'}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                    {!isReachingEnd && !size && (
                        <MoreButton
                            onClick={onClickMoreFetchTx}
                            className={'mx-auto mt-[10px]'}
                            renderRight={<IconNext className={'w-[16px] fill-gray01 dark:fill-dark_gray01'} />}
                        />
                    )}
                </>
            ) : (
                <EmptyComponent
                    text={t('wallet.transactionHistory.empty.text')}
                    className={'h-36 bg-transparent dark:bg-transparent'}
                />
            )}
        </>
    )
}

export default WalletDepositHistoryTableComponent
