import React, {useMemo} from 'react'
import {TableHeaderContainer} from '@component/tables/TableHeaderContainer'
import Text from '@component/Text/Text'
import EmptyComponent from '@feature/common/components/EmptyComponent/EmptyComponent'
import WalletTransactionCategoryTag from '@feature/account/pages/wallet/dashboard/WalletTransactionHistoryContainer/CategoryTag/WalletTransactionCategoryTag'
import {useTranslation} from 'next-i18next'
import useFormatNumber from '@hook/useFormatNumber'
import useFormatDate from '@hook/useFormatDate'
import useQueryPaymentTransaction from '@hook/query/account/wallet/useQueryPaymentTransaction'
import {IWalletTransaction} from '@api/user/account/wallet/getWalletTransaction'
import MoreButton from '@feature/common/components/MoreButton/MoreButton'
import IconNext from '@svg/common/ic_btn_next.svg'
import {createLog} from '@util/logs'

export interface IWalletTable {
    className?: string
    size?: number
}

const WalletAllHistoryTableComponent: React.FC<IWalletTable> = ({className, size}) => {
    const {t} = useTranslation()
    const {displayNumber} = useFormatNumber()
    const {displayDateFromText} = useFormatDate()
    const {data: paymentAllData, isReachingEnd, fetchNextPage} = useQueryPaymentTransaction('all')

    const paymentAllList = useMemo(() => {
        let list: IWalletTransaction[] = []
        paymentAllData?.pages?.map(page => page?.map(item => list.push(item)))
        return size ? list.slice(0, size) : list
    }, [paymentAllData, size])

    const onClickMoreFetchTx = () => {
        createLog('event', 'account_wallet_history_click_more_fetch_history_all')
        void fetchNextPage()
    }

    return (
        <>
            <TableHeaderContainer className={'grid grid-cols-4 md:hidden'}>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                    {t('wallet.history.all.table.column.categories')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                    {t('wallet.history.all.table.column.date')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                    {t('wallet.history.all.table.column.amount')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                    {t('wallet.history.all.table.column.asset')}
                </Text>
            </TableHeaderContainer>

            {paymentAllList?.length > 0 ? (
                <>
                    {paymentAllList?.map(item => (
                        <div
                            data-cy={'walletHistory_list'}
                            key={item.id}
                            className={`grid grid-cols-4 md:grid-cols-2 py-[15px] px-[20px] md:px-0 items-center md:gap-y-[5px] ${className}`}>
                            <WalletTransactionCategoryTag category={item.action} />
                            <Text className={'md:text-right text-gray01 dark:text-dark_gray01 sm:text-body2'}>
                                {displayDateFromText(item.created_at, t('dateFormat.dateAndTime'))}
                            </Text>
                            <div className={'flex justify-between items-center md:col-span-2'}>
                                <Text className={'hidden md:block text-gray01 dark:text-dark_gray01 sm:text-body2'}>
                                    {t('wallet.history.all.table.row.amount')}
                                </Text>
                                <Text className={'text-ti1 text-gray01 dark:text-dark_gray01 sm:text-ti2'}>
                                    {displayNumber(Math.abs(item.amount), 2)}
                                </Text>
                            </div>
                            <div className={'flex justify-between items-center md:col-span-2'}>
                                <Text className={'hidden md:block text-gray01 dark:text-dark_gray01 sm:text-body2'}>
                                    {t('wallet.history.all.table.row.asset')}
                                </Text>
                                <Text className="text-gray01 dark:text-dark_gray01 sm:text-body2">{item.coin}</Text>
                            </div>
                        </div>
                    ))}
                    {!isReachingEnd && !size && (
                        <MoreButton
                            onClick={onClickMoreFetchTx}
                            className={'mx-auto mt-[10px]'}
                            renderRight={<IconNext className={'w-[16px] fill-gray01 dark:fill-dark_gray01'} />}
                        />
                    )}
                </>
            ) : (
                <EmptyComponent
                    text={t('wallet.transactionHistory.empty.text')}
                    className={'h-36 bg-transparent dark:bg-transparent'}
                />
            )}
        </>
    )
}

export default WalletAllHistoryTableComponent
