import React, {useImperativeHandle, useMemo, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import Text from '@component/Text/Text'
import {IInvoice} from '@type/invoice'
import useFormatNumber from '@hook/useFormatNumber'
import WizButton from '@component/buttons/WizButton'
import useNavigation from '@hook/useNavigation'
import {apiPayInvoice} from '@api/user/account/invoice/pay/payInvoice'
import useSnackbar from '@hook/useSnackbar'
import BigNumber from 'bignumber.js'
import WizContentModal from '@component/modal/WizContentModal'
import useQueryGetPendingInvoices from '@hook/query/user/account/invoices/useQueryGetPendingInvoices'
import useQueryGetInvoices from '@hook/query/user/account/invoices/useQueryGetInvoices'
import useQueryUserWallets from '@hook/query/user/account/wallet/useQueryUserWallets'
import {createLog} from '@util/logs'
import IconErrorMessage from '@svg/common/ic_error_message.svg'
import IconWarning from '@svg/common/ic_warning_triangle.svg'

interface IProps extends IInvoice {}

const OtherInvoicePayModal = ({user_nickname, amount, paid, id}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()
    const {displayBalance} = useFormatNumber()
    const {data: userWallets} = useQueryUserWallets()
    const {refresh: refreshPendingInvoices} = useQueryGetPendingInvoices()
    const {refresh: refreshInvoiceHistory} = useQueryGetInvoices()

    const {showSnackbar, showErrorSnackbar} = useSnackbar()

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
        close: () => {
            modalRef.current.close()
        },
    }))

    const {goWallet} = useNavigation()

    const onClickProceed = async () => {
        createLog('event', 'account_invoice_pay_invoice', {invoice_id: id})
        const {data, error} = await apiPayInvoice(id)
        if (!data) {
            createLog('error', 'account_invoice_pay_invoice_failed', {invoice_id: id})
            showErrorSnackbar(error)
            return
        }
        //some changes happened calling refreshing..
        refreshInvoiceHistory()
        refreshPendingInvoices()
        if (data.remaining === 0) {
            closeModal()
            showSnackbar(t('modal.invoicePayOtherModal.res.success'))
            return
        }
        if (data.remaining > 0) {
            closeModal()
            showSnackbar(t('modal.invoicePayOtherModal.res.success.remain', {count: data?.remaining}))
            return
        }
    }

    const closeModal = () => {
        modalRef?.current?.close()
    }

    const dueAmount = useMemo(() => new BigNumber(amount).minus(paid), [amount, paid])
    const remainAfterCut = useMemo(
        () => new BigNumber(userWallets?.total_value || 0).minus(dueAmount),
        [userWallets?.total_value, dueAmount],
    )

    return (
        <>
            <WizContentModal size={'600'} ref={modalRef} title={t('modal.invoicePayOtherModal.title')}>
                <div className={'pt-[5px] sm:pt-[28px]'}>
                    <Text className={'text-body3 sm:text-body2 text-gray02 dark:text-dark_gray02'}>
                        {t('modal.invoicePayOtherModal.desc')}
                    </Text>
                </div>
                <Text className={'text-ti2 text-gray01 dark:text-dark_gray01 mt-[30px] px-[10px] sm:px-0'}>
                    {t('modal.invoicePayOtherModal.summary.title', {name: user_nickname})}
                </Text>
                <div
                    className={
                        'mt-[10px] bg-blue_tint02 dark:bg-dark_blue_tint02 rounded-[5px] py-[20px] px-[40px] sm:px-[20px] space-y-[10px]'
                    }>
                    <div>
                        <div className={'flex justify-between'}>
                            <Text className={'text-body1 sm:text-body2 text-gray02 dark:text-dark_gray02'}>
                                {t('modal.invoicePayOtherModal.summary.walletBalance.title')}
                            </Text>
                            <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                {displayBalance(userWallets?.total_value, {showPreSign: true})}
                            </Text>
                        </div>
                        <div className={'flex justify-between mt-[10px] sm:mt-[5px]'}>
                            <Text className={'text-body1 sm:text-body2 text-gray02 dark:text-dark_gray02'}>
                                {t('modal.invoicePayOtherModal.summary.invoiceAmount.title')}
                            </Text>
                            <Text className={'text-ti2 text-red_shade dark:text-dark_red_shade'}>
                                {displayBalance(dueAmount, {showPreSign: true})}
                            </Text>
                        </div>
                    </div>
                    <div className={'border-b border-blue_tint_01 dark:border-dark_blue_tint01'} />
                    <div className={'flex justify-between'}>
                        <Text className={'text-body1 sm:text-ti2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.invoicePayOtherModal.summary.remainingBalance.title')}
                        </Text>
                        <Text
                            className={`text-ti2 ${
                                remainAfterCut.isPositive()
                                    ? 'text-gray01 dark:text-dark_gray01'
                                    : 'text-red_shade dark:text-dark_red_shade'
                            }`}>
                            {displayBalance(remainAfterCut, {showPreSign: true})}
                        </Text>
                    </div>
                </div>

                {!remainAfterCut.isNegative() && (
                    <>
                        <Text
                            className={
                                'text-body1 text-gray02 dark:text-dark_gray02 sm:text-body2 mt-[10px] px-[10px] sm:px-0 '
                            }>
                            {t('modal.invoicePayOtherModal.askConfirm')}
                        </Text>
                        <div className={'flex items-center gap-x-[5px] px-[10px] sm:px-0 sm:gap-x-[10px]'}>
                            <IconWarning className={'w-[24px] flex-none fill-red dark:fill-dark_red sm:hidden'} />
                            <IconErrorMessage
                                className={'w-[16px] flex-none fill-red dark:fill-dark_red hidden sm:block'}
                            />
                            <Text className={'text-body1 sm:text-body3 text-red_shade dark:text-dark_red_shade'}>
                                {t('modal.invoicePayOtherModal.askConfirm.warning.notYourAccount')}
                            </Text>
                        </div>
                        <div className={'flex mt-[30px] space-x-[10px]'}>
                            <WizButton
                                className={'flex-1'}
                                buttonType={'border'}
                                text={t('modal.invoicePayOtherModal.submit.cancel')}
                                onClick={closeModal}
                            />
                            <WizButton
                                className={'flex-1'}
                                buttonType={'primary'}
                                text={t('modal.invoicePayOtherModal.submit.proceed')}
                                onClick={onClickProceed}
                            />
                        </div>
                    </>
                )}
                {remainAfterCut.isNegative() && (
                    <>
                        <div className={'flex items-center space-x-[5px] mt-[10px] '}>
                            <IconErrorMessage className={'w-[16px] flex-none fill-red dark:fill-dark_red'} />
                            <Text className={'text-body1 sm:text-body3 text-red_shade dark:text-dark_red_shade'}>
                                {t('modal.invoicePayOtherModal.askConfirm.warning.insufficientBalance')}
                            </Text>
                        </div>
                        <div className={'flex mt-[30px] space-x-[10px]'}>
                            <WizButton
                                className={'flex-1'}
                                buttonType={'border'}
                                text={t('modal.invoicePayOtherModal.submit.cancel')}
                                onClick={closeModal}
                            />
                            <WizButton
                                className={'flex-1'}
                                buttonType={'primary'}
                                text={t('modal.invoicePayOtherModal.submit.deposit')}
                                onClick={goWallet}
                            />
                        </div>
                    </>
                )}
            </WizContentModal>
        </>
    )
}

export default React.forwardRef(OtherInvoicePayModal)
