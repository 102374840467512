import React, {useCallback} from 'react'
import WalletAllHistoryTableComponent, {
    IWalletTable,
} from '@feature/account/pages/wallet/WalletAllHistoryTableComponent/WalletAllHistoryTableComponent'
import WalletDepositHistoryTableComponent from '@feature/account/pages/wallet/WalletDepositHistoryTableComponent/WalletDepositHistoryTableComponent'
import WalletEarnHistoryTableComponent from '@feature/account/pages/wallet/WalletEarnHistoryTableComponent/WalletEarnHistoryTableComponent'
import WalletUseHistoryTableComponent from '@feature/account/pages/wallet/WalletUseHistoryTableComponent/WalletUseHistoryTableComponent'
import WalletWithdrawHistoryTableComponent from '@feature/account/pages/wallet/WalletWithdrawHistoryTableComponent/WalletWithdrawHistoryTableComponent'

interface IProps extends IWalletTable {
    className?: string
    size?: number
    action?: string
}

const WalletHistoryTableComponent: React.FC<IProps> = ({className, action, size}) => {
    const renderHistoryTable = useCallback(() => {
        switch (action) {
            case 'deposit':
                return <WalletDepositHistoryTableComponent size={size} />
            case 'withdraw':
                return <WalletWithdrawHistoryTableComponent size={size} />
            case 'earn':
                return <WalletEarnHistoryTableComponent size={size} />
            case 'use':
                return <WalletUseHistoryTableComponent size={size} />
            case 'all':
                return <WalletAllHistoryTableComponent size={size} />
        }
    }, [action, size])

    return <div className={`${className}`}>{renderHistoryTable()}</div>
}

export default WalletHistoryTableComponent
