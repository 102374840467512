import React from 'react'
import Text from '@component/Text/Text'
import {createLog} from '@util/logs'

interface IProps {
    className?: string
    text?: string
    colorClassName?: string
}

const ConnectSupportText: React.FC<IProps> = ({className, text, colorClassName}) => {
    return (
        <Text
            onClick={() => {
                // @ts-ignore
                window?.Intercom('show')
                createLog('event', 'Support_Clicked')
            }}
            className={`cursor-pointer text-body2 ${
                colorClassName ? colorClassName : 'text-gray02 dark:text-dark_gray02'
            } underline ${className}`}>
            {text}
        </Text>
    )
}

export default ConnectSupportText
