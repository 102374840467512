import React from 'react'
import Text from '@component/Text/Text'
import WizImage from '@component/images/WizImage'
import ic_faq_guide from '@image/ic_faq_guide.png'
import IconNewWindow from '@svg/common/ic_new_window.svg'
import {createLog} from '@util/logs'

interface IProps {
    className?: string
    text?: string
    link?: string
}

const ApiGuideBlock: React.FC<IProps> = ({className, text, link}) => {
    return (
        <div
            onClick={() => {
                createLog('event', 'account_api_list_guide_click')
                window.open(link)
            }}
            className={`py-[10px] px-[20px] bg-primary_tint02 dark:bg-dark_primary_tint02 rounded-[5px] space-x-[10px] flex items-center cursor-pointer ${className}`}>
            <WizImage src={ic_faq_guide} width={50} />
            <Text className={'text-ti2 text-gray02 dark:text-dark_gray02 flex-1'}>{text}</Text>
            <IconNewWindow width={16} className={'fill-gray02 dark:fill-dark_gray02'} />
        </div>
    )
}

export default ApiGuideBlock
